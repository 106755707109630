import {Directive,ElementRef,HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
	selector:'[deedlyAdminInputEmptyToNull]',
	standalone:true
})
export class InputEmptyToNullDirective{
	private elementRef:ElementRef;
	private ngControl:NgControl;
	
	constructor(elementRef:ElementRef,ngControl:NgControl){
		this.elementRef=elementRef;
		this.ngControl=ngControl;
	}
	
	@HostListener('input',['$event.target'])
	onEvent(target:HTMLInputElement):void{
		this.putValue(target);
	}
	
	@HostListener('blur',['$event.target'])
	onEvent2(target:HTMLInputElement):void{
		this.putValue(target);
	}
	
	putValue(target:HTMLInputElement):void{
		this.ngControl!.control!.setValue((target.value==='') ? null : target.value);
	}
}
