<mat-toolbar class="drawer-header">
	<button mat-icon-button type="button" (click)="closeMenu()" aria-label="Close">
		<mat-icon class="app-24">close</mat-icon>
	</button>
	<div class="spacer"></div>
	<button mat-icon-button type="button" (click)="appService.toggleDarkTheme()" aria-label="Toggle dark mode" [matTooltip]="(appService.theme | async)==='app-dark-theme' ? 'Light mode' : 'Dark mode'">
		<mat-icon>{{(appService.theme | async)==='app-dark-theme' ? 'light_mode' : 'dark_mode'}}</mat-icon>
	</button>
</mat-toolbar>
<div>
	<mat-nav-list>
		<a *ngIf="appService.user | async" mat-list-item [routerLink]="['/admin']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Home</span>
		</a>
		<a *ngIf="appService.user | async" mat-list-item [routerLink]="['/admin/users']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Users</span>
		</a>
		<a *ngIf="appService.user | async" mat-list-item [routerLink]="['/admin/charities']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Charities</span>
		</a>
		<a *ngIf="appService.user | async" mat-list-item [routerLink]="['/admin/charity-vaults']" [routerLinkActive]="'tab-active mat-elevation-z1'" [routerLinkActiveOptions]="{exact:true}" (click)="closeMenu()" class="mat-elevation-z0">
			<span>Charity vaults</span>
		</a>
	</mat-nav-list>
</div>
