import {afterNextRender,Component,OnInit} from '@angular/core';
import {AppService} from './app.service';
import {HeaderComponent} from './misc/header/header.component';
import {DrawerComponent} from './misc/drawer/drawer.component';
import {SharedModule} from './shared/shared.module';

@Component({
	selector:'deedly-admin-root',
	standalone:true,
	imports:[
		SharedModule,
		HeaderComponent,
		DrawerComponent
	],
	templateUrl:'./app.component.html',
	styleUrl:'./app.component.scss'
})
export class AppComponent implements OnInit{
	title:string;
	appService:AppService;
	
	constructor(appService:AppService){
		this.title='Deedly admin';
		this.appService=appService;
		
		afterNextRender(():void=>{
			this.appService.document.defaultView?.scrollTo(0,0);
		});
	}
	
	ngOnInit():void{
		this.appService.updateSeo(
			this.title
		);
		
		
	}
	
	
}
