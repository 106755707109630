import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomMaterialModule} from './custom-material.module';
import {RouterModule} from '@angular/router';
import {NgOptimizedImage} from '@angular/common';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {NgxFileDropModule} from 'ngx-file-drop';

@NgModule({
	declarations:[],
	imports:[
		RouterModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		CustomMaterialModule,
		NgOptimizedImage,
		NgxFileDropModule
	],
	exports:[
		RouterModule,
		CommonModule,
		CustomMaterialModule,
		FormsModule,
		ReactiveFormsModule,
		NgOptimizedImage,
		NgxFileDropModule
	],
	providers:[]
})
export class SharedModule{
}
