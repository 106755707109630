import {Component,EventEmitter,Input,Output} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../app.service';
import {SharedModule} from '../../shared/shared.module';

@Component({
	selector:'deedly-admin-drawer',
	standalone:true,
	imports:[
		SharedModule
	],
	templateUrl:'./drawer.component.html',
	styleUrl:'./drawer.component.scss'
})
export class DrawerComponent{
	@Output() public drawerClosed:EventEmitter<void>=new EventEmitter<void>();
	@Input() drawerInput?:MatDrawer;
	appService:AppService;
	
	constructor(appService:AppService){
		this.appService=appService;
		this.drawerInput=undefined;
	}
	
	closeMenu():void{
		this.drawerClosed.emit();
	}
}
