export const environment={
	production:false,
	apiServer:{
		url:'https://api-test.deedly.com'
	},
	webServer:{
		url:'https://test.deedly.com'
	},
	adminServer:{
		url:'https://admin-test.deedly.com'
	},
	appServer:{
		url:'https://app-test.deedly.com'
	}
};
