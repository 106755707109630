import {Component,Input} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../app.service';
import {SharedModule} from '../../shared/shared.module';
import {AuthenticationService} from '../../authentication/authentication.service';

@Component({
	selector:'deedly-admin-header',
	standalone:true,
	imports:[
		SharedModule
	],
	templateUrl:'./header.component.html',
	styleUrl:'./header.component.scss'
})
export class HeaderComponent{
	@Input() drawerInput?:MatDrawer;
	appService:AppService;
	authenticationService:AuthenticationService;
	
	constructor(
		appService:AppService,
		authenticationService:AuthenticationService
	){
		this.appService=appService;
		this.authenticationService=authenticationService;
		this.drawerInput=undefined;
	}
	
	
}
